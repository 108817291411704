import axios from "axios";
import React, { useEffect, useState } from "react";
import Compfooter from "./Compfooter";
import { useParams } from "react-router-dom";
import Pembayaran2 from "../../pages/Pembayaran2";
import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Compfoto from "./Compfoto";
import Compwasit from "./Compwasit";
import Combola from "./Combola";
import Comprompi from "./Compromi";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [wasit, setWasit] = useState([]);
  const [video, setVideo] = useState([]);
  const [foto, setFoto] = useState([]);
  const [bola, setBola] = useState([]);
  const [rompi, setRompi] = useState([]);
  const [idwasit, setIdwasit] = useState("");
  const [idvideo, setIdvideo] = useState("");
  const [idfoto, setIdfoto] = useState("");
  const [idrompi, setIdrompi] = useState("");
  const [idbola, setIdbola] = useState("");
  const [hargawasit, setHargawasit] = useState(0);
  const [hargavideo, setHargavideo] = useState(0);
  const [hargafoto, setHargafoto] = useState(0);
  const [hargarompi, setHargarompi] = useState(0);
  const [hargabola, setHargabola] = useState(0);
  const [jambooking, setJambooking] = useState([]);
  const [hargabooking, setHargabooking] = useState(0);
  const [pagewasit, setPagewasit] = useState(false);
  const [pagevideo, setPagevideo] = useState(false);
  const [pagefoto, setPagefoto] = useState(true);
  const [pagerompi, setPagerompi] = useState(false);
  const [pagebola, setPagebola] = useState(false);
  const [lapangan, setLapangan] = useState("");
  const [sistempembayaran, setSistempembayaran] = useState("");
  const [namateam, setNamateam] = useState("");
  const [wa, setWa] = useState("");
  const [status, setStaus] = useState("false");
  const [selectedItems, setSelectedItems] = useState([]);




  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const totalharga =
    Number(hargabooking) +
    Number(hargavideo) +
    Number(hargawasit) +
    Number(hargafoto) +
    Number(hargarompi) +
    Number(hargabola);

  const { idlap, idjam, itemjam, tgl } = useParams();

  const getwasit = async () => {
    try {
      const response = await axios.get(urlapi + "Wasit");
      setWasit(response.data);
    } catch (error) { }
  };

  const getbola = async () => {
    try {
      const response = await axios.get(urlapi + "Bola");
      setBola(response.data);
      // console.log(response.data);
    } catch (error) { }
  };

  const getrompi = async () => {
    try {
      const response = await axios.get(urlapi + "Rompi");
      setRompi(response.data);
    } catch (error) { }
  };

  const getvideo = async () => {
    try {
      const response = await axios.get(urlapi + "Videografer");
      // console.log(response.data);
      setVideo(response.data);
    } catch (error) { }
  };

  const getfoto = async () => {
    try {
      const response = await axios.get(urlapi + "Fotografer");
      setFoto(response.data);
    } catch (error) { }
  };

  const getdatajam = async () => {
    try {
      const response = await axios.get(urlapi + "Jambookingbaru?id=" + itemjam);
      setJambooking(response.data);
      setHargabooking(response.data.total_harga);
    } catch (error) { }
  };

  const getlapangan = async () => {
    try {
      const response = await axios.get(urlapi + "Lapangan?id=" + idlap);
      setLapangan(response.data.lapangan);
    } catch (error) { }
  };

  const handlewasit = (harga, checked) => {
    if (checked == false) {
      setHargawasit(Number(hargawasit) - Number(harga));
    } else {
      setHargawasit(Number(hargawasit) + Number(harga));
    }

  };

  const handlevideo = async (id) => {
    if (id == 0) {
      setHargavideo(0);
      setIdvideo("");
    } else {
      // console.log(id);
      gethargavideo(id);
      setIdvideo(id);
    }
  };

  const handlefoto = (harga, checked) => {
    if (checked == false) {
      setHargafoto(Number(hargafoto) - Number(harga));
    } else {
      setHargafoto(Number(hargafoto) + Number(harga));
    }
  };

  const handlerompi = (harga, checked) => {
    if (checked) {
      setHargarompi(Number(hargarompi) + Number(harga))
    } else {
      setHargarompi(Number(hargarompi) - Number(harga));
    }
  };

  const handlebola = (harga, checked) => {
    if (checked) {
      setHargabola(Number(hargabola) + Number(harga))
    } else {
      setHargabola(Number(hargabola) - Number(harga));
    }
  };

  const gethargawasit = async (id) => {
    try {
      const response = await axios.get(urlapi + "Wasit?id=" + id);
      setHargawasit(response.data.harga);
    } catch (error) { }
  };

  const gethargavideo = async (id) => {
    try {
      const response = await axios.get(urlapi + "Videografer?id=" + id);
      setHargavideo(response.data.harga);
    } catch (error) { }
  };

  const gethargafoto = async (id) => {
    try {
      const response = await axios.get(urlapi + "Fotografer?id=" + id);
      setHargafoto(response.data.harga);
    } catch (error) { }
  };

  const gethargarompi = async (id) => {
    try {
      const response = await axios.get(urlapi + "Rompi?id=" + id);
      setHargarompi(response.data.harga);
    } catch (error) { }
  };

  const gethargabola = async (id) => {
    try {
      const response = await axios.get(urlapi + "Bola?id=" + id);
      setHargabola(response.data.harga);
    } catch (error) { }
  };

  const cekbooking = async () => {
    try {
      const response = await axios.get(
        urlapi +
        "Cekbookinglapanganterbaru?idlap=" +
        idlap +
        "&&idjam=" +
        idjam +
        "&&tgl=" +
        tgl
      );
      setStaus("true");
    } catch (error) {
      setStaus("false");
    }
  };

  const hapusItem = (id, tanggal) => {
    const storedData = JSON.parse(localStorage.getItem('selectedItems')) || [];
    // Filter data untuk menghapus item dengan id tertentu
    const updatedData = storedData.filter((item) => item.id !== id || item.tanggal !== tanggal);
    // Simpan kembali data yang telah diperbarui ke localStorage
    localStorage.setItem('selectedItems', JSON.stringify(updatedData));
    setSelectedItems(updatedData)
    toast.success("Jam booking berhasil di hapus !", {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  const totalhargabooking = () => {
    const storedData = JSON.parse(localStorage.getItem('selectedItems')) || [];
    const totalHarga = storedData.reduce((total, item) => {
      return total + parseInt(item.harga_diskon, 10); // Pastikan harga diubah menjadi angka
    }, 0);
    return totalHarga;
  };

  const totalhargaall = totalhargabooking() + Number(hargafoto) + Number(hargawasit) + Number(hargabola) + Number(hargarompi);


  useEffect(() => {
    setSelectedItems(JSON.parse(localStorage.getItem('selectedItems')))
    getrompi();
    getfoto();
    getwasit();
    getvideo();
    getbola();
    getdatajam();
    getlapangan();
  }, []);
  return (
    <div style={{ height: "100%" }} id="pagecheckout">
      <a href="#pagecheckout" id="buttonklik" style={{ display: "none" }}>
        KLIK
      </a>
      <div className="container">
        <br />
        <div className="card mt-5 mb-3 text-white" id="bglistpemain">
          <div className="card-body">
            <h5>Halaman checkout </h5>
            <small>
              <i className="fas fa-"></i>
              Anda dapat menambahkan layanan wasit, fotografer, videografer,
              rompi dan bola untuk booking lapangan anda
            </small>
          </div>
        </div>
        <hr />

        {status == "true" ? <></> : <></>}

        <div className="card">
          <div className="card-body">
            <div className="mt-3" style={{ color: "#2b2e5a" }}>
              <small className="fw-bold "><i className="fas fa-calendar-days"></i> Jadwal booking yang anda pilih  </small>
              </div>
            <br />
            {selectedItems.map((data, index) => {
              return (
                <div key={index}>
                  <div className="card mb-2 shadow" style={{ borderColor : '#2b2e5a' }}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between  fw-bold" style={{ color: "#2b2e5a" }}>
                        <div>
                          <small><i className="fas fa-clock"></i> {data.jam_mulai} - {data.jam_berakhir}</small>
                          <div> <small><i className="fas fa-coins"></i> {formatrupiah(data.harga_diskon)}</small></div>
                        </div>
                        <div>
                          <small><i className="fas fa-calendar-day"></i> {data.tanggal}</small>
                          <div className="text-end">
                  
                            <span onClick={() => hapusItem(data.id, data.tanggal)} class="badge bg-danger"><i className="fas fa-trash"></i></span>
                          </div>
                        </div>
                      </div>
                      <small style={{ color: '#2b2e5a', fontWeight: 'bold' }}><i className="fas fa-futbol"></i> { data.nama_lapangan }</small>
                      <hr />


                      <Compfoto sendhargafoto={handlefoto} index={index} />

                      <Compwasit sendhargawasit={handlewasit} index={index} />

                      <Combola sendhargabola={handlebola} index={index} />

                      <Comprompi sendhargarompi={handlerompi} index={index} />
                    </div>
                  </div>
                </div>
              )
            })}


          </div>
        </div>



        <div className="card mb-3 shadow mt-3">
          <div className="card-body">
            <label className="fw-bold">Pembayaran</label>
            <br />
            <small className="text-secondary">
              Pembayaran booking lapangan hanya dapat di lakukan dengan
              <b>
                {" "}
                BANK TRANSFER dengan nomor rekening tujuan REK BCA : 6475383951
                a/n Pendy Or Handoko{" "}
              </b>
              , Pembayaran Booking Lapangan Wajib DP 50% dari Harga yang sudah
              di tentukan, dengan syarat pelunasan (H-1) 1 hari sebelum jadwal
              main
            </small>
          </div>
        </div>
      </div>

      <div className="container">
        <div class="alert alert-primary" role="alert">
          <small className="fw-bold">
            Jadwal booking yang sudah di booking tidak dapat di cancel dan
            diubah, mohon perhatikan waktu dan tanggal saat booking lapangan
          </small>
        </div>
      </div>

      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row mb-3 text-secondary">
              <div className="form-group mt-3 col-md-6">
                <label>Lapangan</label>
                <input
                  type="text"
                  value={lapangan}
                  className="form-control mt-3 fw-bold"
                />
              </div>
              <div className="form-group  mt-3 col-md-6">
                <label>Harga Lapangan</label>
                <br />
                <input
                  type="text"
                  value={totalhargaall}
                  placeholder="xxxxxxx"
                  className="form-control mt-3 fw-bold "
                />
              </div>
            </div>
            <div className="row mb-3 text-secondary">
              <div className="form-group mt-3 col-md-6">
                <p>Pembayaran</p>
                <div class="form-check form-check-inline">
                  <input
                    className="form-check-input border-primary"
                    type="radio"
                    name="inlineRadioOptions"
                    id="lunas"
                    onClick={() => setSistempembayaran("Lunas")}
                    value="Lunas"
                  />

                  <label className="form-check-label" for="inlineRadio1">
                    Lunas
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input border-primary"
                    type="radio"
                    name="inlineRadioOptions"
                    id="dp"
                    onClick={() => setSistempembayaran("Dp")}
                    value="DP"
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    DP 50%
                  </label>
                </div>
              </div>
              <div className="form-group  mt-3 col-md-6">
                <label className="mb-2">Transfer</label>
                <input
                  type="text"
                  className="form-control fw-bold"
                  required=""
                  value={sistempembayaran == "Dp" ? totalhargaall / 2 : totalhargaall}
                />
              </div>
            </div>
            <div className="row mb-3 text-secondary">
              <div className=" col-md-6 form-group mt-3 text-secondary">
                <label className="mb-2">Nama Team</label>
                <input
                  type="text"
                  className="form-control"
                  required=""
                  placeholder="Sinar FC"
                  value={namateam}
                  onChange={(e) => setNamateam(e.target.value)}
                />
              </div>

              <div className="form-group col-md-6 mt-3 text-secondary">
                <label className="mb-2">No Whatsapp</label>
                <input
                  type="number"
                  className="form-control"
                  required=""
                  placeholder="08xxxxxxxx"
                  value={wa}
                  onChange={(e) => setWa(e.target.value)}
                />
                <small className="text-primary" style={{ fontSize: "8px" }}>
                  Masukan nomor Whatsapp anda dengan benar untuk mendapatkan
                  notifikasi pembayaran anda
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="mt-5">
        <div className="card fixed-bottom" style={{ border: "none" }}>
          <div className="card-body">
            <div className="container">
              <div className="d-flex justify-content-between">
                <div>
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    Total : {formatrupiah(totalhargaall)}
                  </label>
                </div>
                <div>
                  {wa == "" || sistempembayaran == "" || namateam == "" ? (
                    <>
                      <Button
                        variant="btn rounded-pill disabled"
                        style={{ backgroundColor: "#2b2e5a", color: "white" }}
                      >
                        Bayar sekarang
                      </Button>
                    </>
                  ) : (
                    <>
                      <Pembayaran2
                        idlapangan={idlap}
                        lapangan={lapangan}
                        harga={totalhargabooking()}
                        namateam={namateam}
                        jambooking={idjam}
                        wa={wa}
                        tgl={tgl}
                        totalharga={totalhargaall}
                        wasit={idwasit}
                        video={idvideo}
                        foto={idfoto}
                        rompi={idrompi}
                        bola={idbola}
                        sistempembayaran={sistempembayaran}
                        item={selectedItems}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Compfooter
          hargalap={hargabooking}
          wasit={hargawasit}
          video={hargavideo}
          lapangan={lapangan}
          team={namateam}
          wa={wa}
          pembayaran={sistempembayaran}
        /> */}
      </div>
      <ToastContainer />
    </div>
  );
}
