import React from "react";
import { Link } from "react-router-dom";

export default function Footerbooking({
  harga,
  idlap,
  idjam,
  tglsrc,
  statusbtn,
  item
}) {
  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  return (
    <div>
      <div className="card fixed-bottom" style={{ borderRadius: "0px" }}>
        <div className="card-body">
          <div className="container">
            <div className="d-flex justify-content-between">
              {harga == "" ? (
                <h5 className="fw-bold" style={{ color: "#2b2e5a" }}>
                  Rp.0 {}
                </h5>
              ) : (
                <h5 className="fw-bold" style={{ color: "#2b2e5a" }}>
                  {formatrupiah(harga)}
                </h5>
              )}

              <div>
                {item == '' ? (
                  <>
                    <button
                      className="btn btn-primary disabled rounded-pill"
                      style={{ backgroundColor: "#2b2e5a" }}
                    >
                      Selanjutnya
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <a
                      href={"/checkout/" + idlap}
                      className="btn btn-primary rounded-pill"
                      style={{ backgroundColor: "#2b2e5a" }}
                    >
                      Selanjutnya
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
