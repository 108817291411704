import React, { useEffect, useRef } from "react";
import { useState } from "react";
import axios from "axios";
import Comppembayaranrankteam from "../Membarkarirteam/Comppembayaranrankteam";
import { useParams } from "react-router-dom";

export default function Compmain() {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [foto, setFoto] = useState([]);
  const [pagefoto, setPagefoto] = useState(true);
  const [hargafoto, setHargafoto] = useState(0);
  const [idfoto, setIdfoto] = useState("");
  const [team, setTeam] = useState([]);
  const [game, setGame] = useState([]);
  const [harga, setHarga] = useState(0);
  const [datasesi, setDatasesi] = useState([]);
  const [totalharga, setTotalharga] = useState(0);

  const { kode_main, kode_team, sesi } = useParams();
  const buttonRef = useRef(null);

  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const getfoto = async () => {
    try {
      const response = await axios.get(
        urlapi + "Fotograferrankteam?rankteam=true"
      );
      setFoto(response.data);
    } catch (error) {}
  };

  const handlefoto = async (id) => {
    // console.log(id);
    if (id == 0) {
      setHargafoto(0);
      setIdfoto("");
    } else {
      gethargafoto(id);
      setIdfoto(id);
    }
  };

  const gethargafoto = async (id) => {
    try {
      const response = await axios.get(urlapi + "Fotograferrankteam?id=" + id);
      setHargafoto(response.data.harga);
    } catch (error) {}
  };

  const geteam = async () => {
    try {
      const response = await axios.get(
        urlapi + "Datateam?id_user=" + localStorage.getItem("id")
      );
      setTeam(response.data);
    } catch (error) {}
  };

  const datagame = async () => {
    try {
      const response = await axios.get(
        urlapi + "Gamerankteam?kode_main=" + kode_main
      );
      if (sesi == 1) {
        setHarga(response.data.harga);
        const num = removeDots(response.data.harga);
        setTotalharga(num);
      } else {
        if (response.data.harga2 == "") {
          setHarga(response.data.harga);
          const num = removeDots(response.data.harga);
          setTotalharga(num);
        } else {
          setHarga(response.data.harga2);
          const num = removeDots(response.data.harga2);
          setTotalharga(num);
        }
      }
      setGame(response.data);
    } catch (error) {}
  };

  const getsesi = async (sesi, kode_main) => {
    try {
      const response = await axios.get(
        urlapi + "Getsesirankteambaru?kode=" + kode_main + "&&sesi=" + sesi
      );
      setDatasesi(response.data);
      //   console.log(response.data);
    } catch (error) {}
  };

  const removeDots = (numberString) => {
    return numberString.replace(/\./g, "");
  };

  const totalharga2 = Number(totalharga) + Number(hargafoto);

  useEffect(() => {
    getfoto();
    geteam();
    datagame();
    getsesi(sesi, kode_main);
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, []);

  return (
    <div id="home">
      <div className="container">
        <br />
        <div className="card mt-5 mb-3 text-white" id="bglistpemain">
          <div className="card-body">
            <h5>Halaman checkout rank team </h5>
            <small>
              <i className="fas fa-"></i>
              Anda dapat menambahkan layanan fotograper di sesi rank team anda{" "}
            </small>
          </div>
        </div>

        {/* layanan fotograger */}
        {foto == false ? (
          <></>
        ) : (
          <>
            <div className="card mb-3 mt-3 shadow" style={{ border: "none" }}>
              <div
                className="card-header"
                onClick={() => setPagefoto(!pagefoto)}
              >
                <div className="d-flex justify-content-between">
                  <label className="fw-bold" style={{ color: "#2b2e5a" }}>
                    <i className="fas fa-camera"></i> Fotograper{" "}
                  </label>
                  <label>
                    <span class="badge rounded-pill bg-danger">New</span>{" "}
                    <i
                      className={
                        pagefoto == true
                          ? "fas fa-angle-down"
                          : "fas fa-angle-right"
                      }
                    ></i>
                  </label>
                </div>
              </div>
              <div className="card-body text-secondary">
                <div className={pagefoto == false ? "" : "d-none"}>
                  <div class="d-flex justify-content-between">
                    {foto == false ? (
                      <>
                        {" "}
                        <small className="text-secondary">
                          <i className="fas fa-circle-info"></i> Layanan
                          fotogragper tidak tersedia
                        </small>
                      </>
                    ) : (
                      <>
                        {" "}
                        <small
                          className="text-secondary"
                          onClick={() => setPagefoto(!pagefoto)}
                        >
                          <i className="fas fa-plus"></i> Tambah layanan
                          fotograper
                        </small>
                      </>
                    )}
                    {hargafoto == "" ? (
                      ""
                    ) : (
                      <i class="fas fa-circle-check text-success"></i>
                    )}
                  </div>
                </div>
                <div className={pagefoto == true ? "" : "d-none"}>
                  {foto.map((data, index) => {
                    return (
                      <div key={index}>
                        {data.keterangan == '' ? <></> : <>
                          <div className="alert alert-primary">
                            <small>{ data.keterangan }</small>
                          </div>
                        </>}
                        <div className="d-flex justify-content-between">
                          <div>
                            <label
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              {data.nama}
                            </label>
                            <br />
                            {data.harga2 == "" ? (
                              <></>
                            ) : (
                              <>
                                {" "}
                                <small className="fw-bold">
                                  <s> {formatrupiah(data.harga2)}</s>
                                </small>
                              </>
                            )}
                            <br />
                            <small
                              className="fw-bold"
                              style={{ color: "#2b2e5a" }}
                            >
                              <span class="badge bg-success">
                                {formatrupiah(data.harga)}
                              </span>
                            </small>{" "}
                            <small className="text-primary">
                              <span class="badge bg-dark">PROFESSIONAL</span>
                            </small>{" "}
                          </div>
                          <div>
                            <div className="form-check">
                              <img
                                src={
                                  "https://ratumerak.id/adminmmsc/assets/berkas/" +
                                  data.foto
                                }
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "50px" }}
                              ></img>
                              <input
                                class="form-check-input  border border-danger"
                                type="radio"
                                name="foto"
                                id="foto"
                                value="foto"
                                onClick={() => handlefoto(data.id)}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-between">
                    <div>
                      <label className="fw-bold">Tanpa fotograper</label>
                      <br />
                      <small className="">Rp. 0</small>
                    </div>
                    <div>
                      <div className="form-check border-danger">
                        <input
                          className="form-check-input border border-danger"
                          type="radio"
                          name="foto"
                          id="foto"
                          value="foto"
                          onClick={() => handlefoto(0)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <hr />
        <div class="alert alert-primary" role="alert">
          <small className="fw-bold">
            {" "}
            Jadwal rank team yang sudah di booking tidak dapat di ubah dan di
            cancel, pastikan jadwal yang anda pilih sudah benar
          </small>
        </div>
        <div className="card mb-3 shadow">
          <div className="card-body">
            <label className="fw-bold">Pembayaran</label>
            <br />
            <small className="text-secondary">
              Pembayaran rank team hanya dapat di lakukan dengan
              <b>
                {" "}
                BANK TRANSFER dengan nomor rekening tujuan REK BCA : 6475383951
                a/n Pendy Or Handoko{" "}
              </b>
              . Pembayaran rank team wajib dilakukan sesuai dengan jumlah yang
              tertera diaplikasi
            </small>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col">
              <label className="fw-bold mb-2">Team</label>
              <input
                type="text"
                value={team.nama_team}
                className="form-control"
                aria-label="First name"
                disabled
              />
            </div>
            <div className="col">
              <label className="fw-bold mb-2">Tanggal main</label>
              <input
                type="text"
                className="form-control"
                aria-label="Last name"
                disabled
                value={game.tgl_main}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className="fw-bold mb-2">Sesi </label>
              <input
                type="text"
                className="form-control"
                aria-label="First name"
                disabled
                value={sesi}
              />
            </div>
            <div className="col">
              <label className="fw-bold mb-2">Jam main</label>
              <input
                type="text"
                className="form-control"
                aria-label="Last name"
                value={datasesi.jam_main}
                disabled
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className="fw-bold mb-2">Harga </label>
              <input
                type="text"
                className="form-control"
                aria-label="First name"
                disabled
                value={"Rp " + harga}
              />
            </div>
            <div className="col">
              <label className="fw-bold mb-2">Total harga</label>
              <input
                type="text"
                className="form-control"
                aria-label="Last name"
                disabled
                value={formatrupiah(totalharga2)}
              />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <Comppembayaranrankteam
            harga={harga}
            total_harga={totalharga2}
            kode_main={kode_main}
            kode_team={kode_team}
            sesi={sesi}
            foto={idfoto}
          />
        </div>
        <a ref={buttonRef} href="#home" style={{ display: "none" }}>
          Link
        </a>
      </div>
    </div>
  );
}
