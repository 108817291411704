import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function Comprompi({ sendhargarompi, index }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [rompi, setRompi] = useState([]);
  const [show, setShow] = useState(false);
  const [valrompi, setValrompi] = useState('');
  const [items, setItems] = useState([])

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Rompi");
      setRompi(response.data);
    } catch (error) { }
  }

  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const handlerompi = (e, id, harga) => {
    const isChecked = e.target.checked;
    const storedItems = JSON.parse(localStorage.getItem("selectedItems"));
    const updatedItems = [...storedItems];
    const newItemData = {
      idrompi: id,
      hargarompi: harga
    };
    if (isChecked) {
      setValrompi(id)
      sendhargarompi(harga, true);
      updatedItems[index] = {
        ...updatedItems[index], // Salin data lama
        ...newItemData, // Update data baru
      };
    } else {
      setValrompi('')
      sendhargarompi(harga, false);
      const updatedItems = [...storedItems];
      if (updatedItems[index]) {
        delete updatedItems[index].hargarompi;
        delete updatedItems[index].idrompi;
      }
    }
    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
    setItems(updatedItems);
  }

  useEffect(() => {
    getdata()
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      setItems(JSON.parse(storedItems)); // Set data ke state
    }
  }, [])
  return (
    <div>
      <div className='card mt-1'>
        <div className='card-body'>

          <div className='d-flex justify-content-between fw-bold mb-2' onClick={() => setShow(!show)}>
            <small><i className='fas fa-shirt'></i> Rompi</small>
            <small>{valrompi != '' ? <i className='fas fa-circle-check text-success'></i> : ''}{' '}<i className={show == false ? 'fas fa-angle-right' : 'fas fa-angle-down'}></i></small>
          </div>
          <div className={show == false ? 'd-none' : ''}>
            {rompi.map((data, index) => {
              return (
                <div>
                  <div className='d-flex justify-content-between' key={index}>
                    <div>
                      <small style={{ color: "#2b2e5a" }} className='fw-bold'>{data.rompi}</small>

                      <div><span className='badge rounded-pill bg-success'>{formatrupiah(data.harga)}</span></div>
                    </div>
                    <div>
                      <div class="form-check">
                        <img
                          src={
                            "https://ratumerak.id/adminmmsc/assets/berkas/" +
                            data.foto
                          }
                          className="img-fluid"
                          alt="Responsive image"
                          style={{ height: "40px" }}
                        ></img>
                        <input
                          class="form-check-input  border border-danger"
                          type="checkbox"
                          name="foto"
                          id="foto"
                          onChange={(e) => handlerompi(e, data.id, data.harga)}
                        />
                      </div>

                    </div>
                  </div>
                  <hr />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
