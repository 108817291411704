import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Complogoteam from "../Membarkarirteam/Complogoteam";
import Compwinrate from "../Comprankkompetition/Compwinrate";
import Compprofil from "../Comprankkompetition/Compprofil";
import { Link } from "react-router-dom";
import Compmatchrankteam from "./Compmatchrankteam";
import Compjmlpemain from "../Comprankkompetition/Compjmlpemain";
import Complogoteamrank from "./Complogoteamrank";
import { ToastContainer, toast } from "react-toastify";

export default function Compmainrankleague() {
  const urlapi = process.env.REACT_APP_BASE_URL;

  const [ranksolo, setRanksolo] = useState([]);
  const [rankteam, setRankteam] = useState([]);
  const [rank, setRank] = useState([]);
  const [gamesolo, setGamesolo] = useState([]);
  const [gamerankteam, setGamerankteam] = useState([]);
  const [point, setPoint] = useState([]);
  const [setuju, setSetuju] = useState(false);
  const [setuju2, setSetuju2] = useState(false);

  const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + tgl.getDate()).slice(-2);

  const [tglday, setTglday] = useState(format_tgl);

  const getpertandinganranksolo = async () => {
    try {
      const response = await axios.get(urlapi + "Pertandinganranksoloterbaru");
      //   console.log(response.data);
      setRanksolo(response.data);
    } catch (error) {}
  };

  const getpertandinganrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Pertandinganrankteamterbaru");
      //   console.log(response.data);
      setRankteam(response.data);
    } catch (error) {}
  };

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Point_memberkarir?rank=true");
      setRank(response.data);
    } catch (error) {}
  };

  const mainranksolo = async () => {
    try {
      const response = await axios.get(urlapi + "Getfungame");
      // console.log(response.data);
      setGamesolo(response.data);
    } catch (error) {
      // console.log(error.message);
    }
  };

  const mainrankteam = async () => {
    try {
      const response = await axios.get(urlapi + "Fungamerankteam");
      // console.log(response.data);
      setGamerankteam(response.data);
    } catch (error) {
      setGamerankteam("");
    }
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1.5,
    },
  };

  const getPoint = async () => {
    try {
      const response = await axios.get(urlapi + "Pointrankteam");

      setPoint(response.data);
    } catch (error) {}
  };

  const notify = () =>
    toast.warning("Fitur ini belum tersedia !", {
      position: toast.POSITION.TOP_CENTER,
    });

  const cekperaturan = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Peraturan?ranksolo=true&&id_user=" +
          localStorage.getItem("id")
      );
      // console.log(response.data);
      if (response.data !== "") {
        setSetuju(true);
      }
    } catch (error) {}
  };

  const cekperaturan2 = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Peraturan?rankteam=true&&id_user=" +
          localStorage.getItem("id")
      );
      // console.log(response.data);
      if (response.data != "") {
        setSetuju2(true);
      }
    } catch (error) {
      setSetuju2(false);
    }
  };

  useEffect(() => {
    getpertandinganranksolo();
    getpertandinganrankteam();
    getdata();
    mainranksolo();
    mainrankteam();
    getPoint();
    cekperaturan();
    cekperaturan2();
  }, []);

  return (
    <div>
      <div className="card border-0 shadow" style={{ borderRadius: "0px" }}>
        <div className="card-body mt-5">
          <img
            src="./img/bennerbaru.png"
            className="img-fluid"
            style={{ borderRadius: "10px" }}
          ></img>
        </div>

        <div className="container">
          <div className="card border-0" id="bggradient1">
            <div className="card-body text-white">
              <h5 className="fw-bold">Hello {localStorage.getItem("nama")}</h5>
              <small>
                Selamat datang di Rank League, pilih rank league yang anda
                inginkan
              </small>
            </div>
          </div>

          {gamesolo == "" || tglday > gamesolo.tgl_main ? (
            <>
              {" "}
              <div className="alert alert-danger border-0 mt-2">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank solo ditutup,
                  cobalah mendaftar dilain hari{" "}
                </small>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="alert alert-primary border-0 mt-2">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank solo dibuka, buruan
                  daftar sekarang juga{" "}
                </small>
              </div>
            </>
          )}

          {gamerankteam != false ? (
            <>
              <div className="alert alert-primary border-0">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank team dibuka, buruan
                  daftar sekarang juga{" "}
                </small>
              </div>
            </>
          ) : (
            <>
              <div className="alert alert-danger border-0">
                <small className="fw-bold">
                  <i className="fas fa-circle-info"></i> Hello{" "}
                  {localStorage.getItem("nama")} match rank team belum tersedia,
                  cobalah dilain hari
                </small>
              </div>
            </>
          )}

          <div>
            <div className="d-flex justify-content-between fw-bold">
              <small className="fw-bold text-secondary">
                <i className="fas fa-shield-halved"></i> Menu rank league
              </small>
              <i className="fas fa-ellipsis-vertical text-secondary"></i>
            </div>
          </div>
          <div className="card mt-2  shadow">
            <div className="card-body">
              <div
                className="row mt-2 fw-bold"
                style={{ fontSize: "10px", color: "#2b2e5a" }}
              >
                <div className="col-sm-4 col-4">
                  <center>
                    <Link to="/ranksolo">
                      <img
                        src="./img/menu/logors.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                    </Link>

                    <br />
                    <small>Rank solo</small>
                  </center>
                </div>
                <div className="col-sm-4 col-4">
                  <center>
                    <Link to="/rankteam">
                      <img
                        src="./img/menu/logort.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "50px",
                        }}
                      ></img>
                      <br />
                    </Link>
                    <small>Rank team</small>
                  </center>
                </div>
                {/* <div className="col-sm-3 col-3">
                  <center>
                    <Link to="/rankingsolo">
                      <img
                        src="./img/tr.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "30px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>Ranking solo</small>
                  </center>
                </div> */}
                {/* <div className="col-sm-3 col-3">
                  <center>
                    <Link to="/rankingteam">
                      <img
                        src="./img/trophy.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "30px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>Ranking team</small>
                  </center>
                </div> */}
                {/* <div className="col-sm-3 col-3 mt-3">
                  <center>
                    <Link to="/hasilranksolo">
                      <img
                        src="./img/google.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "30px",
                        }}
                      ></img>
                      <br />
                    </Link>
                    <small>Hasil rank solo</small>
                  </center>
                </div> */}
                {/* <div className="col-sm-3 col-3 mt-3">
                  <center>
                    <Link to="/hasilrankteam">
                      <img
                        src="./img/ss.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "30px",
                        }}
                      ></img>
                      <br />
                    </Link>
                    <small>Hasil rank team</small>
                  </center>
                </div> */}

                {/* <div className="col-sm-3 col-3 mt-3">
                  <center>
                    <Link to="/profilteam">
                      <img
                        src="./img/fc.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "30px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>Profil team</small>
                  </center>
                </div> */}

                {/* <div className="col-sm-3 col-3 mt-3">
                  <center>
                    <Link to="/datateamrankteam">
                      <img
                        src="./img/fc.png"
                        className="img-fluid mb-2"
                        style={{
                          borderRadius: "10px",
                          height: "30px",
                        }}
                      ></img>
                    </Link>
                    <br />
                    <small>Data team</small>
                  </center>
                </div> */}

                <div className="col-sm-4 col-4 ">
                  <center>
                    <img
                      src="./img/menu/lainya.png"
                      className="img-fluid mb-2"
                      style={{
                        borderRadius: "10px",
                        height: "50px",
                      }}
                      onClick={() => notify()}
                    ></img>
                    <br />
                    <small>Lainya</small>
                  </center>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            {" "}
            <img
              src="img/rl22.png"
              className="img-fluid"
              alt="Responsive image"
              style={{ borderRadius: "10px" }}
            ></img>
          </div>

          <div className="card mt-2">
            <div className="card-body">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={4}
                nav={false}
                responsive={resvonsive}
                dotsEach={true}
                dots={true}
                autoplay={true}
              >
                <img
                  src="img/crousel/rs1.png"
                  style={{ borderRadius: "10px" }}
                  className="img-fluid"
                  alt="Responsive image"
                ></img>
                <img
                  src="img/crousel/rs2.png"
                  style={{ borderRadius: "10px" }}
                  className="img-fluid"
                  alt="Responsive image"
                ></img>
                {/* <img
                  src="img/crousel/rsolo.png"
                  style={{ borderRadius: "10px" }}
                  className="img-fluid"
                  alt="Responsive image"
                ></img> */}
                <img
                  src="img/crousel/rt.png"
                  style={{ borderRadius: "10px" }}
                  className="img-fluid"
                  alt="Responsive image"
                ></img>
                <img
                  src="img/crousel/rt2.png"
                  style={{ borderRadius: "10px" }}
                  className="img-fluid"
                  alt="Responsive image"
                ></img>
                <img
                  src="img/crousel/rt3.png"
                  style={{ borderRadius: "10px" }}
                  className="img-fluid"
                  alt="Responsive image"
                ></img>
              </OwlCarousel>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
