import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Complawan from "../Memberkarir/Complawan";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Comppemain({ kodemain2, team2, lawan2 }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const { kodemain } = useParams();
  const [pemain, setPemain] = useState([]);
  const [pagepemain, setPagepemain] = useState(true);
  const [lawan, setLawan] = useState([]);
  const [show, setShow] = useState(false);

  const getDatalawan = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Pemainmemberkarir?id_user=" +
          localStorage.getItem("id") +
          "&&kodemain=" +
          kodemain
      );
      setLawan(response.data.lawan);
    } catch (error) {}
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        urlapi +
          "Pemainmemberkarir?id_user=" +
          localStorage.getItem("id") +
          "&&kodemain=" +
          kodemain
      );
      setPemain(response.data.team);
      //   setPemain(response.data.team);
    } catch (error) {
      console.log(error.message);
    }
  };

  const resvonsive = {
    0: {
      items: 1.5,
    },
    600: {
      items: 1.5,
    },
    1000: {
      items: 1.5,
    },
  };

  const getshow = async () => {
    try {
      const response = await axios.get(urlapi + "Getshow");
      if (response.data.show == 1) {
        setShow(true);
      } else {
        setShow(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    getDatalawan();
    getshow();
  }, []);

  return (
    <div>
      <br />
      <div className="container">
        <OwlCarousel
          className="owl-theme mt-5"
          loop
          margin={4}
          nav={false}
          responsive={resvonsive}
          dotsEach
          autoplay
        >
          <img
            src="/img/bennerbooking.png"
            className="img-fluid"
            alt=""
            style={{ borderRadius: "5px" }}
          ></img>
          <img
            src="/img/dp.png"
            className="img-fluid"
            alt=""
            style={{ borderRadius: "5px" }}
          ></img>
        </OwlCarousel>
      </div>
      <div className="card" style={{ height: "1010px", border: "none" }}>
        <div className="card-body">
          <div className="card" style={{ borderRadius: "20px" }}>
            <div
              className="card-body text-white"
              id="bglistpemain"
              style={{ borderRadius: "5px" }}
            >
              <h5 className="fw-bold">Halaman peserta </h5>
              <label>Halaman peserta rank solo</label>
            </div>
          </div>

          <div className="card shadow mt-3">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-6">
                  <p className="text-secondary">
                    <i className="fas fa-shield-halved"></i> Team{" "}
                    {team2}
                  </p>
                  {pemain.map((data, index) => {
                    return (
                      <div key={index}>
                        {/* <Link
                          to={"/detailpemain/" + data.id_user}
                          style={{ textDecoration: "none", color: "black" }}
                        > */}
                        <div className="d-flex justify-content-between">
                          <div>
                            <img
                              src="/img/pm.png"
                              className="img-fluid"
                              alt="Responsive image"
                              style={{ height: "20px" }}
                            ></img>
                            <label
                              className="fw-bold"
                              style={{ marginLeft: "10px", fontSize: "10px" }}
                            >
                              {show == true ? <>{data.nama}</> : "Player"}
                              {data.posisi == "Penjaga gawang" ||
                              data.posisi == "Penjaga Gawang" ? (
                                <> (GK)</>
                              ) : (
                                <></>
                              )}
                              
                            </label>
                          </div>
                        </div>
                        <hr />
                        {/* </Link> */}
                      </div>
                    );
                  })}
                </div>

                <div className="col-sm-6 col-6">
                  <p className="text-secondary">
                    <i className="fas fa-shield-halved"></i> Team{" "}
                    {lawan2 == "Putih" ? "Hijau" : lawan2}
                  </p>
                  {lawan.map((data, index) => {
                    return (
                      <div key={index}>
                        <Link
                          to={"/detailpemain/" + data.id_user}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              <img
                                src="/img/pm.png"
                                className="img-fluid"
                                alt="Responsive image"
                                style={{ height: "20px" }}
                              ></img>
                              <label
                                className="fw-bold"
                                style={{ marginLeft: "10px", fontSize: "10px" }}
                              >
                                {show == true ? <>{data.nama}</> : "Player"}
                                {data.posisi == "Penjaga gawang" ||
                                data.posisi == "Penjaga Gawang" ? (
                                  <> (GK)</>
                                ) : (
                                  <></>
                                )}
                                {/* {data.kapten == 0 ? " (C)" : ""} */}
                              </label>
                            </div>
                          </div>
                          <hr />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
