import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function Compfoto({ sendhargafoto, index }) {
  const [foto, setFoto] = useState([]);
  const [show, setShow] = useState(false)
  const urlapi = process.env.REACT_APP_BASE_URL
  const [valfoto, setValfoto] = useState('')
  const [items, setItems] = useState([]);

  const getfoto = async () => {
    try {
      const response = await axios.get(urlapi + "Fotografer");
      setFoto(response.data);
    } catch (error) { }
  };

  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const handlefoto = (e, idfoto, hargafoto) => {
    const isChecked = e.target.checked;
    const storedItems = JSON.parse(localStorage.getItem("selectedItems"));
    const updatedItems = [...storedItems];
    const newItemData = {
      idfoto: idfoto,
      hargafoto: hargafoto
    };
    if (isChecked) {
      setValfoto(idfoto);
      sendhargafoto(hargafoto, true);
      updatedItems[index] = {
        ...updatedItems[index], // Salin data lama
        ...newItemData, // Update data baru
      };
    } else {
      setValfoto('')
      sendhargafoto(hargafoto, false);
      const updatedItems = [...storedItems];
      if (updatedItems[index]) {
        delete updatedItems[index].hargafoto;
        delete updatedItems[index].idfoto;
      }

    }
    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
    setItems(updatedItems);
  }
  useEffect(() => {
    getfoto();
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      setItems(JSON.parse(storedItems)); // Set data ke state
    }
  }, [])
  return (
    <div className='card mt-1'>
      <div className='card-body'>
        <div className='d-flex justify-content-between fw-bold mb-2' onClick={() => setShow(!show)}>
          <small><i className='fas fa-camera'></i> Fotograper</small>
          <small>{valfoto != '' ? <i className='fas fa-circle-check text-success'></i> : ''} {' '} <i className={show == false ? 'fas fa-angle-right' : 'fas fa-angle-down'}></i></small>
        </div>
        <div className={show == false ? 'd-none' : ''}>
          {foto.map((data, index) => {
            return (
              <div key={index}>
                <div className='d-flex justify-content-between' key={index}>
                  <div>
                    <small style={{ color: "#2b2e5a" }} className='fw-bold'>{data.nama}</small>
                    <div>
                      {data.harga2 == '' ? '' : <small><s>{formatrupiah(data.harga2)}</s></small>}
                    </div>
                    <div><span className='badge rounded-pill bg-success'>{formatrupiah(data.harga)}</span></div>
                  </div>
                  <div>
                    <div class="form-check">
                      <img
                        src={
                          "https://ratumerak.id/adminmmsc/assets/berkas/" +
                          data.foto
                        }
                        className="img-fluid"
                        alt="Responsive image"
                        style={{ height: "40px" }}
                      ></img>
                      <input
                        class="form-check-input  border border-danger"
                        type="checkbox"
                        name="foto"
                        onChange={(e) => handlefoto(e, data.id, data.harga)}
                        id="foto"
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

