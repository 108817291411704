import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';
import axios from 'axios';
import {  toast } from "react-toastify";
import Loading from './Loading';
import StatusBookedrankteam from '../../pages/StatusBookedrankteam';

export default function Jammain({ kode_voucher, idlap, lapangan}) {
    const urlapi = process.env.REACT_APP_BASE_URL
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [jammain, setJammin] = useState([]);
    const [jambook, setJambook] = useState('');
    const [idbook, setIdbook] = useState('');
    const [lap, setLap] = useState('');
    const [cekbookinglap, setCekbookinglap] = useState([])
    const [hiddbutton, setHiddenbutton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pesansukses, setPesansukses] = useState(false);
    const [btn, setBtn] = useState(false);
    const [selectedItem, setSelectedItems] = useState([]);
    const [jmlbook, setJmlbook] = useState(0);
    

    const Time = new Date();
    const hour = ("0" + Time.getHours()).slice(-2);
    const menit = Time.getMinutes();
    const jam = hour + "." + menit;

 const date = new Date();
  let tgl = new Date();
  let format_tgl =
    tgl.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
        ("0" + tgl.getDate()).slice(-2);
    
  const [tglsrc, setTglsrc] = useState(format_tgl);
  

    const getjam = async (tgl) => {
        try {
            const response = await axios.get(urlapi + "Jammain3?tgl=" + tgl + "&&lapangan=" + idlap)
            setJammin(response.data)
            // console.log(response.data);
        } catch (error) {
            console.log(error.message);
        }
    }

    const handlejam = (book, id) => {
        setJambook(book)
        setIdbook(id)
        cekbookinglap2(id, tglsrc, book);
    }

    const handletgl = (val) => {
        getjam(val)
        setTglsrc(val);
        cekBookinglapangan(val, idlap)
    }

     const notify = () =>
        toast.error("Jam booking belum anda pilih !", {
      position: toast.POSITION.BOTTOM_CENTER,
        });
    
       const notify2 = () =>
        toast.error("Jam booking sudah digunakan !", {
      position: toast.POSITION.BOTTOM_CENTER,
        });
    
    const getlapangan = async () => {
        try {
            const response = await axios.get(urlapi + "Lapangan?id=" + idlap);
            setLap(response.data.lapangan)
        } catch (error) {
            
        }
    }

    const cekBookinglapangan = async (tglsrc, idlap) => {
        try {
        const response = await axios.get(
            urlapi + "Cekbookinglapangan?id_lap=" + idlap + "&tgl=" + tglsrc
        );
            setCekbookinglap(response.data);
            // console.log(response.data);
        } catch (error) {}
    };

    const cekbookinglap2 = async (idjam, tglsrc) => {
        
        try {
            const response = await axios.get(urlapi + "Cekbookinglapangan2?idjam=" + idjam + "&&tgl=" + tglsrc + "&&idlap=" + idlap)
            if (response.data.data.iduser == "Memberkarir team") {
                    cekMemberkarirteam(response.data.data.kode_booking);
            } else {
                if (response.data.status == true) {
                    notify2()
                    setHiddenbutton(true)
                } else {
                    setHiddenbutton(false)
                } 
            }
            
            // console.log(response.data);
            
        } catch (error) {
            console.log(error.messages);
            setHiddenbutton(false)
        }
    }

    const addbooking = async () => {
    await axios
      .post(urlapi + "Addbookingbaru2", {
        nama: localStorage.getItem("nama"),
        iduser: localStorage.getItem("id"),
        id_lapangan: idlap,
        lapangan: lap,
        tgl: tglsrc,
        harga: 111111,
        team: kode_voucher,
        jam: idbook,
        bukti: '',
        norek: '',
        atasnama: localStorage.getItem('nama'),
        sistempembayaran: 'Lunas',
        totalharga: '',
        wa: '',
        wasit: '',
        video: '',
        foto: '',
        rompi: '',
        bola: '',
        voucher: 1,
        img: "voucher.png",
        item : selectedItem
      })

      .then((response) => {
        // console.log(response.data);
          // resetrankteam();
          setLoading(false)
          setPesansukses(true);
          localStorage.removeItem('jamvoucher')
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(true);
      });
  };

    
    const handlebooking = () => {
        if (jambook == '') {
            notify();
        } else {  
            setLoading(true);
            addbooking()
        }
    }

    const bookingvoucher = async () => {
        try {
            const response = await axios.get(urlapi + "Bookingvoucher?kode=" + kode_voucher);
            if (response.data.voucher != null || response.data != false) {
                setBtn(true);
            } else {
                setBtn(false)
            }
        } catch (error) {
            // console.log(error.message);
            setBtn(false);
        }
    }

    const cekMemberkarirteam = async (kodebooking) => {
    try {
      const response = await axios.get(
        urlapi + "Cekbookingrankteambooking?kode_main=" + kodebooking
      );
      if (response.data.status == true) {
            notify2()
            setHiddenbutton(true)
      } else {
            setHiddenbutton(false)
      }
    } catch (error) {
            setHiddenbutton(false)
    }
    };
    
    const handleItem = (e, item) => {
        const isChecked = e.target.checked;
         let updatedItems = [...selectedItem];
        if (isChecked) {
            updatedItems.push({ ...item, tanggal: tglsrc, idfoto: '', idwasit: '', idbola: '', idrompi: '' });
            
        } else {
            updatedItems = updatedItems.filter(i => i.id !== item.id || i.tanggal !== tglsrc);
           
        }
        setSelectedItems(updatedItems);
        localStorage.setItem('jamvoucher', JSON.stringify(updatedItems));
         jumlahdata()
    }

    const getDataById = (id, tgl) => {
        const storedData = JSON.parse(localStorage.getItem('jamvoucher')) || [];
        const result = storedData.find((item) => item.id == id && item.tanggal == tgl);
        return result
    };

    const jumlahdata = () => {
        const storedData = localStorage.getItem("jamvoucher");
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const count = Array.isArray(parsedData) ? parsedData.length : 0;
            setJmlbook(count);
        } else {
            setJmlbook(0)
        }
    }

    useEffect(() => {
        getjam(tglsrc);
        getlapangan();
        cekBookinglapangan(tglsrc, idlap)
        bookingvoucher()
        jumlahdata();
    }, [])

  return (
      <div>
          {btn == false ? <>
              <div className="d-flex justify-content-around mt-3"> 
                <a target='_blank' href={'https://ratumerak.id/adminmmsc/Voucher?kode=' + kode_voucher} className='btn btn-sm rounded-pill text-white' style={{ backgroundColor: '#24254B' }}><i className='fas fa-download'></i> Unduh Voucher</a>
                <Button onClick={handleShow} className="rounded-pill text-white btn-sm" style={{ backgroundColor: '#24254B' }}>
                    <i className='fas fa-ticket'></i> Gunakan Voucher
                </Button>
                
                </div>
          </> : <>
            <small className='text-danger fw-bold'><i className='fas fa-ticket'></i> Sudah digunakan</small>
          </>}
          
          
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="bottom"
            style={{ height: "500px" }}
        >
        <Offcanvas.Header>
                <Offcanvas.Title>{ kode_voucher }</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
                  {/* Pilih jam booking lapangan anda  */}

                  <div className='container'>
           
                      <br />
                      <div className={loading == false && pesansukses == true ? '' : 'd-none'} style={{ marginTop: '100px' }}>
                          < center>
                              <img src="sukses.png" class="img-fluid" alt="..." style={{ height:'100px' }}></img>
                          <h5 className='text-center mt-2'>Voucher booking lapangan berhasil digunakan</h5>
                          
                              <a href='' className='btn text-white mt-3' style={{ backgroundColor : '#25245B' }}>Tutup popup</a>
                            </center>
                          
                      </div>
                      <div className={loading == false ? 'd-none' : ''} style={{ marginTop : '100px' }}>
                        <Loading />
                      </div>      
                
                      <div className={loading == true  || pesansukses == true ? 'd-none' : 'row'}>
                          <div className='mb-3'>
                             
                              <label className='fw-bold mb-2'>Pilih tanggal booking  </label>
                              <input type='date'  value={tglsrc} className='form-control' placeholder='Pilih tanggal booking' onChange={(e) => handletgl(e.target.value)} />
                          </div>
                          {jammain.map((data, index) => {
                            return (
                                <div
                                key={index}
                                className={jam > data.jam_mulai && format_tgl == tglsrc ? 'd-none' : 'col-sm-4 col-4'}
                                >
                                <div className={jambook == data.jam_mulai ? 'card mb-2 shadow border-primary' : 'card mb-2 shadow'}
                                    onClick={(e) => handlejam(data.jam_mulai, data.id, e)}
                                >
                                    <div className="card-body text-center fw-bold">
                                    <small style={{ fontSize: '9px' }}>{data.jam_mulai} - {data.jam_berakhir}</small>
                                    
                                            {/* Menggunakan find untuk memeriksa apakah ada data booking */}
                                            {(() => {
                                                const bookingData = cekbookinglap.find(
                                                (cc) =>
                                                    cc.jam_mulai == data.jam_mulai &&
                                                    cc.status_pembayaran != 400
                                                );

                                                if (bookingData) {
                                                return (
                                                    <div className="text-danger" style={{ fontSize: '8px' }}>
                                                    {bookingData.user == 'Memberkarir team' ? (
                                                        <StatusBookedrankteam kode_main={bookingData.kode_booking} />
                                                    ) : bookingData.jam_mulai == data.jam_mulai &&
                                                        bookingData.status_pembayaran == 201 &&
                                                        bookingData.iduser == localStorage.getItem('id') ? (
                                                        'Menunggu'
                                                    ) : (
                                                        'Booked'
                                                    )}
                                                    </div>
                                                );
                                                } else {
                                                // Jika tidak ada data booking
                                                return (
                                                    <>
                                                        {jmlbook < 2 || getDataById(data.id, tglsrc) != null || selectedItem == '' ? (
                                                <div className="form-check">
                                                <input
                                                    checked={getDataById(data.id, tglsrc) != null ? true : false}
                                                    className="form-check-input border-danger"
                                                    onChange={(e) => handleItem(e, data)}
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault"
                                                />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                                    </>
                                                );
                                                }
                                            })()}

                                            
                                            </div>
                                        </div>
                                        </div>
                                    );
                                    })}

                          {/* <div className='row mt-5'>
                              <div className='col-sm-6 col-6'>
                                  <input type='text' placeholder='Nama team' className='form-control rounded-pill' />
                              </div>

                              <div className='col-sm-6 col-6'>
                                  <input type='text' placeholder='No whatsapp' className='form-control rounded-pill' />
                              </div>
                          </div> */}
                        
                          { jmlbook == 2 ? <>  <button className='btn w-100 text-white rounded-pill mt-3' onClick={() => handlebooking()} style={{ backgroundColor : '#25245B' }}>Booking lapangan</button></> : <>  <button className='btn w-100 text-white rounded-pill mt-3 disabled'  style={{ backgroundColor : '#25245B' }}>Booking lapangan</button></>}
                        
                          {/* <a href='' target='_blank' className='btn btn-primary'>Booking lapangan</a> */}
                      </div>
                  </div>
                  
        </Offcanvas.Body>
          </Offcanvas>
          {/* <ToastContainer /> */}
    </div>
  )
}
