import React from "react";

const Contoh = () => {
  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          {/* Card Container */}
          <div className="card shadow-lg">
            {/* Header */}
            <div className="card-header bg-primary text-white text-center">
              <h3>Sinar FC2</h3>
            </div>

            {/* Logo Tim */}
            <div className="card-body text-center">
              <img
                src="https://via.placeholder.com/100"
                alt="Team Logo"
                className="rounded-circle border border-secondary mb-3"
                style={{ width: "120px", height: "120px" }}
              />
              <p>
                <a href="#" className="text-primary text-decoration-none">
                  Ubah logo team Anda?
                </a>
               </p>
            </div>

            {/* Informasi */}
            <div className="px-4 pb-3">
              <div className="alert alert-info" role="alert">
                <i className="bi bi-info-circle-fill me-2"></i>
                Jumlah maksimal pemain yang didaftarkan sebanyak 25 pemain di
                rank tim 0.
              </div>
              <div className="alert alert-warning" role="alert">
                <i className="bi bi-exclamation-triangle-fill me-2"></i>
                Team yang sudah disetujui tidak dapat menambah pemain dan
                mengubah.
              </div>
            </div>

            {/* Tombol */}
            <div className="card-footer text-center">
              <button className="btn btn-primary w-100">
                Bermain Rank Tim Sekarang
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contoh;
