// import logo from "./logo.svg";
// import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Booking from "./pages/Booking";
import BookingLapangan from "./pages/BookingLapangan";
import Formbooking from "./pages/Formbooking";
import Pay from "./pages/Pay";
import About from "./pages/About";
import Login from "./pages/Login";
import Intro from "./pages/Intro";
import Member from "./pages/Member";
import Profil from "./pages/Profil";
import Jadwalmember from "./pages/Jadwalmember";
import Listjadwalbooking from "./componenst/compbooking/Listjadwalbooking";
import Listbookingkarir from "./pages/Listbookingkarir";
import Profilanda from "./pages/Profilanda";
import Score from "./pages/Score";
import Statistik from "./pages/Statistik";
import Register from "./pages/Register";
import Verifikasi from "./pages/Varifikasi";
import Install from "./pages/Install";
import Sendwa from "./pages/Sendwa";
import Setujui from "./pages/Setujui";
import Lupapassword from "./pages/Lupapassword";
import Liga from "./pages/Liga";

import Memberkarir from "./pages/Memberkarir";
import History from "./pages/History";
import Pemainmemberkarir from "./pages/Pemainmemberkarir";
import Profilpemain from "./pages/Profilpemain";
import Hasilmemberkarir from "./pages/Hasilmemberkarir";
import Pemainmemberkarir2 from "./pages/Pemainmemberkarir2";
import Pemainmemberkariranda from "./pages/Pemainmemberkariranda";
import Rankkompetisi from "./pages/Rankkompetisi";
import Memberkarirteam from "./pages/Memberkarirteam";
import Hasilranksolo from "./pages/Hasilranksolo";
import Daftarteamrankteam from "./pages/Daftarteamrankteam";
import Pemainteam from "./pages/Pemainteam";
import Editpemain from "./pages/Editpemain";
import Tambahpemain from "./pages/Tambahpemain";
import Historyrankteam from "./pages/Historyrankteam";
import Hasilrankteam from "./pages/Hasilrankteam";
import Pemainrankteam from "./pages/Pemainrankteam";

import Bookingrankteamanda from "./pages/Bookingrankteamanda";
import Checkout from "./pages/Checkout";
import Statistikteam from "./pages/Statistikteam";
import Profilteam from "./pages/Profilteam";
import Rankleague from "./pages/Rankleague";
import Rankingsolo from "./pages/Rankingsolo";
import Rankingrankteam from "./pages/Rankingrankteam";
import Datateamrankteam from "./pages/Datateamrankteam";
import Profilpemainrankteam from "./pages/Profilpemainrankteam";
import Bookingspesial from "./pages/Bookingspesial";
import Peraturanranksolo from "./pages/Peraturanranksolo";
import Peraturanrankteam from "./pages/Peraturanrankteam";
import Rankteam from "./pages/Rankteam";
import Databookingrankteam from "./pages/Databookingrankteam";
import Ranksolo from "./pages/Ranksolo";
import Databookinranksolo from "./pages/Databookinranksolo";
import Lainya from "./pages/Lainya";
import Checkoutrankteam from "./pages/Checkoutrankteam";
import Voucher from "./pages/Voucher";
import Contoh from "./pages/Contoh";

function App() {
  return (
    <div>
      <div className="row">
        <div className="col-sm-3"></div>
        <div className="col-sm-6" style={{}}>
          <div className="card comcontent" style={{ border: "0px" }}>
            <Router>
              <Routes>
                <Route path="/" element={<Intro />} />
                <Route path="/home" element={<Home />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/booking/:data" element={<Booking />} />
                <Route
                  path="/bookinglapangan/:slug/:id"
                  element={<BookingLapangan />}
                />
                <Route
                  path="/formbooking/:idlapangan"
                  element={<Formbooking />}
                />
                <Route
                  path="/formbooking/:idlapangan/:tglsrcbooking"
                  element={<Formbooking />}
                />
                <Route path="/about" element={<About />} />
                <Route path="/member" element={<Member />} />
                <Route path="/jadwalmemberkarir" element={<Jadwalmember />} />
                <Route
                  path="/listbookingkarir"
                  element={<Listbookingkarir />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/lupapassword" element={<Lupapassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verifikasi" element={<Verifikasi />} />
                <Route path="/profil" element={<Profil />} />
                <Route path="/profilanda" element={<Profilanda />} />
                <Route path="/statistik" element={<Statistik />} />
                <Route path="/updatescore" element={<Score />} />
                <Route path="/liga" element={<Liga />} />
                <Route path="/install" element={<Install />} />
                <Route path="/wa" element={<Sendwa />} />
                <Route path="/setujui/:iduser" element={<Setujui />} />
                <Route path="/memberkarirbaru" element={<Memberkarir />} />
                {/* <Route
                  path="/memberkarirbaru"
                  element={<Memberkapemainmemberkarir />}
                /> */}
                <Route path="/history" element={<History />} />
                <Route
                  path="/detailpemain/:iduser"
                  element={<Profilpemain />}
                />
                <Route
                  path="/pemain/:kodemain"
                  element={<Pemainmemberkarir />}
                />
                <Route
                  path="/hasilmemberkarir"
                  element={<Hasilmemberkarir />}
                />

                <Route
                  path="/pemainmemberkarir/:kodemain/:team/:lawan"
                  element={<Pemainmemberkarir2 />}
                />

                <Route
                  path="/pemainmemberkarir/:kodemain/:team/:lawan"
                  element={<Pemainmemberkarir2 />}
                />
                <Route
                  path="/pemainmemberkarir/:kodemain/:team/:lawan/:all"
                  element={<Pemainmemberkarir2 />}
                />

                <Route
                  path="/pemainmemberkariranda/:kodemain/:team/:lawan"
                  element={<Pemainmemberkariranda />}
                />
                <Route path="/rankcompetition" element={<Rankkompetisi />} />
                <Route
                  path="/rankcompetition/:rankteam"
                  element={<Rankkompetisi />}
                />

                <Route path="/memberkarirteam" element={<Memberkarirteam />} />
                <Route path="/hasilranksolo" element={<Hasilranksolo />} />
                <Route
                  path="/daftarteamrankteam"
                  element={<Daftarteamrankteam />}
                />

                <Route
                  path="/daftarteamrankteam/:slide"
                  element={<Daftarteamrankteam />}
                />

                <Route path="/pemainteam" element={<Pemainteam />} />
                <Route path="/editpemain/:id" element={<Editpemain />} />
                <Route path="/tambahpemain" element={<Tambahpemain />} />
                <Route path="/historyrankteam" element={<Historyrankteam />} />
                <Route path="/hasilrankteam" element={<Hasilrankteam />} />
                <Route
                  path="/pemainrankteam/:team/:lawan/:kode"
                  element={<Pemainrankteam />}
                />
                <Route
                  path="/bookingrankteamanda"
                  element={<Bookingrankteamanda />}
                />
                <Route
                  path="/checkout/:idlap/"
                  element={<Checkout />}
                />
                
                <Route
                  path="/statistikteam/:team"
                  element={<Statistikteam />}
                />

                <Route path="/profilteam/" element={<Profilteam />} />
                <Route path="/rankleague/" element={<Rankleague />} />
                <Route path="/rankingsolo/" element={<Rankingsolo />} />
                <Route path="/rankingteam/" element={<Rankingrankteam />} />

                <Route
                  path="/datateamrankteam/"
                  element={<Datateamrankteam />}
                />
                <Route
                  path="/profilpemainteam/:id"
                  element={<Profilpemainrankteam />}
                />

                <Route path="/bookingspesial" element={<Bookingspesial />} />
                <Route
                  path="/peraturanranksolo"
                  element={<Peraturanranksolo />}
                />
                <Route
                  path="/peraturanrankteam"
                  element={<Peraturanrankteam />}
                />
                <Route path="/rankteam" element={<Rankteam />} />
                <Route
                  path="/databookingrankteam"
                  element={<Databookingrankteam />}
                />
                <Route path="/ranksolo" element={<Ranksolo />} />
                <Route
                  path="/databookingranksolo"
                  element={<Databookinranksolo />}
                />

                <Route
                  path="/checkoutrankteam/:kode_main/:kode_team/:sesi"
                  element={<Checkoutrankteam />}
                />
                <Route path="/lainya" element={<Lainya />} />
                <Route path="/voucher" element={<Voucher />} />
                <Route path="/contoh" element={<Contoh />} />
              </Routes>
            </Router>
          </div>
        </div>
        <div className="col-sm-3"></div>
      </div>
    </div>
  );
}

export default App;
