import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function Compwasit({ sendhargawasit, index }) {
  const urlapi = process.env.REACT_APP_BASE_URL;
  const [wasit, setWasit] = useState([]);
  const [show, setShow] = useState(false);
  const [valwasit, setValwasit] = useState('')
  const [items, setItems] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(urlapi + "Wasit");
      setWasit(response.data);
    } catch (error) { }
  }

  const formatrupiah = (numb) => {
    const format = numb.toString().split("").reverse().join("");
    const convert = format.match(/\d{1,3}/g);
    const rupiah = "Rp " + convert.join(".").split("").reverse().join("");
    return rupiah;
  };

  const handleCheckboxChange = (e, id, harga) => {
    const isChecked = e.target.checked;
    const storedItems = JSON.parse(localStorage.getItem("selectedItems"));
    const updatedItems = [...storedItems];
    const newItemData = {
      idwasit: id,
      hargawasit: harga
    };
    if (isChecked) {
      setValwasit(id);
      sendhargawasit(harga, true)
      updatedItems[index] = {
        ...updatedItems[index], // Salin data lama
        ...newItemData, // Update data baru
      };
    } else {
      setValwasit('');
      sendhargawasit(harga, false)
      const updatedItems = [...storedItems];
      if (updatedItems[index]) {
        delete updatedItems[index].hargawasit;
        delete updatedItems[index].idwasit;
      }
    }
    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
    setItems(updatedItems);
  }

  useEffect(() => {
    getdata()
    const storedItems = localStorage.getItem("selectedItems");
    if (storedItems) {
      setItems(JSON.parse(storedItems)); // Set data ke state
    }
  }, [])
  return (
    <div>
      <div className='card mt-1'>
        <div className='card-body'>

          <div className='d-flex justify-content-between fw-bold mb-2' onClick={() => setShow(!show)}>
            <small><i className='fas fa-user'></i> Wasit</small>
            <small>{valwasit != '' ? <i className='fas fa-circle-check text-success'></i> : ''}{' '}<i className={show == false ? 'fas fa-angle-right' : 'fas fa-angle-down'}></i></small>
          </div>
          <div className={show == false ? 'd-none' : ''}>
            {wasit.map((data, index) => {
              return (
                <div key={index}>
                  <div className='d-flex justify-content-between' key={index}>
                    <div>
                      <small style={{ color: "#2b2e5a" }} className='fw-bold'>{data.nama}</small>

                      <div><span className='badge rounded-pill bg-success'>{formatrupiah(data.harga)}</span></div>
                    </div>
                    <div>
                      <div class="form-check">
                        <img
                          src={
                            "https://ratumerak.id/adminmmsc/assets/berkas/" +
                            data.foto
                          }
                          className="img-fluid"
                          alt="Responsive image"
                          style={{ height: "40px" }}
                        ></img>
                        <input
                          class="form-check-input  border border-danger"
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, data.id, data.harga)}
                          name="foto"
                          id="foto"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
